import { merge } from '@ecp/utils/common';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentSalesConsumer } from '../../../../../../../libs/env/src/index';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { envSalesDev } from '../../../../../../../libs/env/src/index';
import { env as baseEnv } from './env';

/** DON'T USE IN ANY MODULES. THIS IS WHAT `@ecp/env` ALIAS RESOLVES TO AT BUILD TIME. */
export const env: EnvironmentSalesConsumer = merge({}, envSalesDev, baseEnv, {
  optimizely: {
    sdkKey: 'D9oQLe1BCJcraJ8xd4yKC',
  },
}) as EnvironmentSalesConsumer;
