import { merge } from '@ecp/utils/common';

import { LineOfBusiness } from '@ecp/features/shared/product';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentSalesConsumerShared } from '../../../../../../../libs/env/src/index';
import { env as baseEnv } from '../env';

export const env: EnvironmentSalesConsumerShared = merge({}, baseEnv, {
  static: {
    expId: '2853',
    partnerId: '4',
    title: 'Progressive Home, by Homesite',
    eligibleProducts: ['homesite.home', 'homesite.renters'],
    defaultLineOfBusiness: LineOfBusiness.HOME,
  },
  afiApiKey: '69230a56-41f0-42ec-a254-d6533448557d',
  afiAppname: 'progressive-monoline-renters',
  genesysChatDeploymentId: 'e07b35ea-0f31-479e-90dc-97aa026e2e84',
  genesysChatQueueName: 'HC_Progressive_DMRR',
  genesysChatEnvironment: 'prod',
  chatEnabled: true,
  sapiClientId: '1pdrpyidrWDIurPqRNl8S4UCiv0105xG',
  sapiClientSecret: 'kiZZdIBN8eId2TfVCKzXLTCL_IWhiGM3k_OQhpErGzuPRq8NukNBTFFDTEe07y9w',
});
