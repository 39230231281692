import { merge } from '@ecp/utils/common';

import { env as baseEnv } from '../shared/env.dev';
import { env as baseEnvSales } from './env';
import type { EnvironmentSalesConsumerShared } from './types';

/** DON'T USE IN ANY LIBRARY MODULES. TO BE IMPORTED IN APP ENV MODULES ONLY. */
export const env: EnvironmentSalesConsumerShared = merge<
  EnvironmentSalesConsumerShared,
  EnvironmentSalesConsumerShared,
  EnvironmentSalesConsumerShared,
  EnvironmentSalesConsumerShared
>({}, baseEnv, baseEnvSales, {
  optimizely: {
    sdkKey: '3WduHj5uomHDj9P4n7BTT',
  },
  vehicleApiRoot: 'https://intapiext.amfam.com/vehicle/v2/vehicles',
  salesApiRoot: 'https://qa-salesapi.homesitep2.com/v3/salesapi',
  salesApiRootNoAuth: 'https://qa-salesapi.homesitep2.com/v3/salesapi',
  ecpDalRoot: 'https://dal.ecp-qa.homesitep2.com/v1',
  /**
   * https://amfament.atlassian.net/wiki/spaces/EAB/pages/16346821692/Script+Sources
   * if ecpDalRoot is changed, this must also change to match the script that is associated to the DAL API environment
   */
  botControlScriptSrc:
    'https://13f3592b85d8.us-east-1.captcha-sdk.awswaf.com/13f3592b85d8/jsapi.js',
  botControlEnabled: true,
  sapiAnalyticsApiRoot: 'https://qa-sapianalytics.homesitep2.com/v1/sapi-analytics',
  externalReportApiRoot: 'https://intapiext.amfam.com/externalreport/v1',
  creditCardTokenServiceUri: 'https://qa-cctokengenerator.homesitep2.com/v1/creditcard/token',
  amfamBillingApiRoot: 'https://billingqa.amfamconnect.com/intleg01',
});
