export const enum LineOfBusiness {
  AUTO = 'LINE_OF_BUSINESS.AUTO',
  HOME = 'LINE_OF_BUSINESS.HOME',
  RENTERS = 'LINE_OF_BUSINESS.RENTERS',
  BUNDLE = 'LINE_OF_BUSINESS.BUNDLE',
  BUNDLE_AUTO_RENTERS = 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS',
}

export const enum UnsupportedLineOfBusiness {
  CONDO = 'LINE_OF_BUSINESS.CONDO',
  SECOND_HOME = 'LINE_OF_BUSINESS.SECOND_HOME',
  OTHER_VEHICLES = 'LINE_OF_BUSINESS.OTHER_VEHICLES',
  UMBRELLA = 'LINE_OF_BUSINESS.UMBRELLA',
}

export enum UserSelection {
  BUNDLED = 'PRODUCT.BUNDLED',
  UNBUNDLED = 'PRODUCT.UNBUNDLED',
}
