import { LineOfBusiness, UserSelection } from '@ecp/features/shared/product';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentSalesConsumerShared } from '../../../../../../libs/env/src/index';

export const env: EnvironmentSalesConsumerShared = {
  static: {
    baseExp: 'Renters',
    eligibleProducts: ['homesite.renters'],
    defaultLineOfBusiness: LineOfBusiness.RENTERS,
    defaultUserSelection: UserSelection.UNBUNDLED,
    applicationName: 'ecp-ui-sales-consumer',
    authKey: '8749ccf4-162a-4f63-9f78-2765c67a6544',
  },
  hotjarTrackingId: '3029672',
  product: 'choice',
  datadogService: 'edsp-liftoff',
  datadogApplicationId: '6fa07793-7214-486c-b9bd-aa27aa718c22',
  datadogClientToken: 'pub4c06853aa946e6072588992d6cc2e6e5',
};
